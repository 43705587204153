import React from 'react';
import '../styles/main.scss';
import Icon from 'components/ui-components-v2/Icon';
import GetApp from '@mui/icons-material/GetApp';
import { defaultStyles, FileIcon } from 'react-file-icon';
import Files from 'components/data/Files';
import AssetHelpers from 'components/data/AssetHelpers';

/**
 * FileListItem
 * This shows a file details, for instance in a list of uploaded files
 */
export default class FileListItem extends React.Component {
    /**
     * Called when clicking an item
     */
    viewItem = () => {
        const { file } = this.props;
        Files.downloadFileFromUrl(file.url, `${file.title}.${AssetHelpers.getExtension(file.extension)}`);
    };

    render() {
        const { onRemoveItem, file } = this.props;

        return (
            <div className={'file-list-item '}>
                {file.size < 300 * 1024 && file.type === 'image' ? (
                    <div className="file-list-item__image" style={{ backgroundImage: 'url(' + file.url + ')' }}></div>
                ) : (
                    <div className="file-list-item__extension">
                        <FileIcon extension={file.extension} {...defaultStyles[file.extension]} />
                    </div>
                )}
                <div className="file-list-item__context" onClick={this.viewItem}>
                    <div className="file-list-item__title">{file.title}</div>
                    {file.fileName && (
                        <div className="file-list-item__filename">
                            {file.fileName} - {Math.round(file.size / 1024)}kb
                        </div>
                    )}
                </div>
                <div className="file-list-item__actions">
                    {onRemoveItem && (
                        <div className="file-list-item__remove" onClick={onRemoveItem}>
                            <Icon fontSize="small">delete</Icon>
                        </div>
                    )}
                    <div className="file-list-item__view" onClick={this.viewItem}>
                        <GetApp fontSize="small" />
                    </div>
                </div>
            </div>
        );
    }
}
