import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import DynamicVideo from 'components/channels/video/DynamicVideo';
import EditorData from 'components/editor-data/EditorData';
import Translation from 'components/data/Translation';
import Iframe from './iframe';
import AudioPlayer from './audio-player';
import './../styles/main.scss';

/**
 * DynamicAsset
 * This renders a dynamic asset. This switches between a static image, video, iframe etc.
 */
class DynamicAsset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: uuidv4()
        };

        this.videoRef = React.createRef();
    }

    static defaultProps = {
        width: '100%',
        height: 'auto',
        frameWidth: 0,
        frameHeight: 0,
        type: 'image',
        className: 'dynamic-asset',
        style: {},
        controls: true,
        autoplay: false
    };

    static propTypes = {
        url: PropTypes.string,
        type: PropTypes.oneOf([
            'image',
            'video',
            'iframe',
            'staticAsset',
            'dynamicImage',
            'dynamicPDF',
            'dynamicVideo',
            'dynamicVideoDesigned',
            'dynamicAfterEffects',
            'dynamicInDesign',
            'dynamicPDFDesigned',
            'dynamicImageDesigned'
        ]),
        /** Set visual width */
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** Set visual height */
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /** Set iframe internal width */
        frameWidth: PropTypes.number,
        /** Set iframe internal height */
        frameHeight: PropTypes.number,
        /** Data to postMessage to iframe */
        data: PropTypes.object,
        style: PropTypes.object,
        className: PropTypes.string,
        /* Model to update in case the asset contains draggable */
        assetUpdateModel: PropTypes.string,
        /* Show the controls in a video */
        controls: PropTypes.bool,
        /* Autoplay or not */
        autoplay: PropTypes.bool
    };

    playVideo = () => {
        this.videoRef.current.paused ? this.videoRef.current.play() : this.videoRef.current.pause();
    };

    render() {
        let { height, frameWidth, frameHeight } = this.props;
        const { width, className, bottomSpacing, controls, autoplay, data, assetUpdateModel, urlNavigation, value, image, video, audio } = this.props;
        const style = this.props.style;

        // An inactive template from the template designer returns data.template as 'false'
        // If so, return a message that the template is set to inactive
        if (data && typeof data.template === 'boolean' && data.template === false) {
            return <div className="dynamic-asset__inactive">{Translation.get('template.inactive', 'common')}</div>;
        }

        let type = this.props.type ? this.props.type : '';
        let url = this.props.url;

        if (image) {
            type = 'image';
            url = image.url ? image.url : image;
        } else if (video) {
            type = 'video';
            url = video.url ? video.url : video;
        } else if (audio) {
            type = 'audio';
            url = audio.url ? audio.url : audio;
        }

        // Calculate size
        if (!frameWidth) {
            frameWidth = width;
        }
        if (!frameHeight) {
            frameHeight = height;
        }

        if ((!height || height === 'auto') && width && frameWidth && frameHeight && type !== 'video' && type !== 'image') {
            height = (width * frameHeight) / frameWidth;
        }

        switch (type) {
            // A static image
            case 'image':
                if (url && Object.keys(url).length !== 0) {
                    return <img className={`${className} image`} width={width} height={height} src={url && url.url ? url.url : url} style={style} alt={url} />;
                } else {
                    return <React.Fragment />;
                }
            // A static video asset
            case 'video':
                if (url && Object.keys(url).length !== 0) {
                    return (
                        <video
                            ref={this.videoRef}
                            className={`${className} video`}
                            width={width}
                            height={height}
                            src={url && url.url ? url.url : url}
                            style={style}
                            controls={controls}
                            onClick={!controls ? this.playVideo : null}
                            autoPlay={autoplay}
                        />
                    );
                } else {
                    return <React.Fragment></React.Fragment>;
                }

            // A static audio asset
            case 'audio':
                if (url && Object.keys(url).length !== 0) {
                    return <AudioPlayer url={url && url.url ? url.url : url} />;
                } else {
                    return <React.Fragment></React.Fragment>;
                }
            // An iframe or dynamic image that shows HTML with input
            case 'iframe':
            case 'dynamicImage':
            case 'dynamicImageDesigned':
            case 'dynamicPDFDesigned':
            case 'dynamicPDF':
            case 'dynamicVideoDesigned':
                return (
                    <Iframe
                        style={style}
                        width={width}
                        height={height}
                        url={url}
                        type={type}
                        urlNavigation={urlNavigation}
                        frameWidth={frameWidth}
                        frameHeight={frameHeight}
                        bottomSpacing={bottomSpacing}
                        className={className}
                        data={{ ...data, language: EditorData.getLanguage(), market: EditorData.get('market') }}
                        assetUpdateModel={assetUpdateModel}
                    />
                );

            // A dynamic video asset that we want to insert
            case 'dynamicAfterEffects':
            case 'dynamicVideo':
                return (
                    <DynamicVideo
                        style={style}
                        width={width}
                        height={height}
                        className={className}
                        data={data}
                        value={value}
                        onMutation={this.props.onMutation}
                        controls={controls}
                        autoPlay={autoplay}
                        template={this.props.template}
                    />
                );

            default: // eslint-disable-line
                // eslint-disable-line
                console.error(`DynamicAsset type '${type}' not found`);
                return null;
        }
    }
}

export default DynamicAsset;
