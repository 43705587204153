import StillAsset from './components';
import EditorDynamicDataWrapper from 'components/editor-data/EditorDynamicDataWrapper';

const StillAssetDynamicData = EditorDynamicDataWrapper(StillAsset);

export default StillAssetDynamicData;

export {
    StillAsset,
    StillAssetDynamicData
}

